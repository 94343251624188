import React, { useEffect, useState, useRef, useCallback } from "react"

import Tooltip, { DonutChartTooltipBody } from "./Tooltip"

import { drawCanvas } from "./canvas"
import { useResizeCanvas } from "./hooks"
import { calculateSegments } from "./coordinates"
import { updateOpacities } from "./opacities"
import { donutChart, scale } from "./constants"
import { handleMouseMoveFn, handleDocumentTouch } from "./helpers"
import "./styles.scss"

const ChartCanvas = ({ data }) => {
  const canvasRef = useRef(null)
  const containerRef = useRef(null)
  const segmentHovered = useRef([])
  const segmentOpacities = useRef([])
  const drawRef = useRef(() => {})
  const segments = useRef(data && calculateSegments(data))
  const coordinates = useRef({})

  const [tooltipData, setTooltipData] = useState({ visible: false })

  drawRef.current = useCallback(() => {
    const canvas = canvasRef.current
    const container = containerRef.current

    if (!canvas || !container) return

    canvas.width = coordinates.current.canvasWidth * scale
    canvas.height = coordinates.current.canvasHeight * scale
    canvas.style.width = `${coordinates.current.canvasWidth}px`
    canvas.style.height = `${coordinates.current.canvasHeight}px`

    const ctx = canvas.getContext("2d")
    if (!ctx) return

    ctx.scale(scale, scale)

    const disableLabels =
      container.clientWidth < donutChart.minContainerWidthForRenderLabels

    drawCanvas(
      canvas,
      data,
      coordinates,
      segments.current,
      segmentOpacities.current,
      disableLabels
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const canvas = canvasRef.current
    if (!canvas || !data) return

    segmentOpacities.current = segments.current.map(() => donutChart.maxOpacity)

    const updateOpacitiesFn = updateOpacities(
      drawRef,
      segments.current,
      segmentOpacities,
      segmentHovered
    )

    const handleCanvasMouseLeave = () => {
      updateOpacitiesFn({ reset: true })
      setTooltipData({ visible: false })
      segmentHovered.current = []
    }

    const handleMouseMove = handleMouseMoveFn(
      canvas,
      coordinates,
      segments.current,
      segmentHovered,
      setTooltipData,
      updateOpacitiesFn,
      handleCanvasMouseLeave
    )

    canvas.addEventListener("mousemove", handleMouseMove)
    canvas.addEventListener("mouseleave", handleCanvasMouseLeave)

    const cleanupDocumentTouch = handleDocumentTouch(
      canvas,
      handleCanvasMouseLeave
    )

    return () => {
      canvas.removeEventListener("mousemove", handleMouseMove)
      canvas.removeEventListener("mouseleave", handleCanvasMouseLeave)
      cleanupDocumentTouch()
    }
  }, [data])

  useResizeCanvas(canvasRef, containerRef, coordinates, segments, drawRef, data)
  if (!data) return null
  return (
    <div
      className="DonutChartCanvas"
      ref={containerRef}
      id={`canvasContainer-${data.appId}`}
    >
      <Tooltip
        appId={data.appId}
        followCursor
        content={
          tooltipData.visible && <DonutChartTooltipBody {...tooltipData} />
        }
      >
        <div>
          <canvas ref={canvasRef} />
        </div>
      </Tooltip>
    </div>
  )
}

export default ChartCanvas
