import React from "react"

import * as styles from "./stages.module.scss"
import { STAGES } from "../../data/software-project-estimation"

const StageItem = ({ item }) => {
  return (
    <li className={item.label ? "labeled" : ""}>
      <div>
        <h3>{item.title}</h3>
        <p>{item.text}</p>
      </div>
      {item.label && (
        <div className={`${item.label.dark ? "dark" : "orange"} label`}>
          {item.label.text}
        </div>
      )}
    </li>
  )
}

const Stages = () => {
  return (
    <div className={styles.stagesList__wrapper}>
      <ul className={styles.stagesList}>
        {STAGES.map(item => (
          <StageItem key={item.id} item={item} />
        ))}
      </ul>
    </div>
  )
}

export default Stages
