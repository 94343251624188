import React from "react"

const COMMON_CHARTS_DATA = {
  unit: "hours",
  precision: 0,
  totalText: "Total time spent",
  canvasRadius: 130,
  maxRadiusColor: "#f0b528",
  maxRadiusSize: 0.6,
  outerRadius: 0.925,
  innerRadius: 0.675,
  type: "donut",
}

export const TITLES = {
  related_services: {
    title: (
      <>
        We can offer you more services to
        <strong> cover every stage on your way to success</strong>
      </>
    ),
    strongText:
      "Plan your expenses and define the roadmap before the first line of code created",
    uppercase: (
      <>
        software estimation lays a&nbsp;solid foundation for your product
        success
      </>
    ),
    withIndent: true,
  },
  reasons: {
    title: (
      <>
        Why Do <strong>You Need Cost Estimation</strong> for a Software Project?
      </>
    ),
    strongText: "empower your business to make strategic decisions",
    uppercase: (
      <>
        lead your team to
        <br /> better outcomes
      </>
    ),
    withIndent: true,
  },
  plan: {
    title: (
      <>
        book a call with the <strong>expert</strong>
      </>
    ),
    strongText: "plan effectively and deliver value",
    uppercase: (
      <>
        get accurate <br />
        software <br />
        development estimation
      </>
    ),
    withIndent: true,
  },
  stages: {
    title: (
      <>
        6 Stages of Accurate Software Project
        <strong> Estimation Process</strong> by LaSoft
      </>
    ),
    strongText: "build your product within budget",
    uppercase: (
      <>
        aligni
        <br /> investments with
        <br /> business&nbsp;goals
      </>
    ),
    withIndent: true,
  },
  charts: {
    title: (
      <>
        It takes time and effort to
        <br />
        <strong> develop an accurate estimation</strong>
      </>
    ),
    strongText:
      "according to our estimation methodology we define 6 stages of a project",
    uppercase: <>accuracy grows with understanding</>,
    withIndent: true,
  },
  options: {
    title: (
      <>
        Why Choose LaSoft for Software
        <strong> Development Project Cost Estimation?</strong>
      </>
    ),
    strongText: "scale efficiently and avoid costly missteps",
    uppercase: (
      <>
        our estimation
        <br /> helps allocate
        <br /> resources
        <br /> effectively
      </>
    ),
    withIndent: true,
  },
  accordion: {
    title: (
      <>
        What software
        <br /> does LaSoft{" "}
        <strong>
          use for
          <br /> estimating
        </strong>{" "}
        software development costs?
      </>
    ),
    strongText: <>avoid running out&nbsp;of resources</>,
    uppercase: (
      <>
        use our
        <br /> estimation to
        <br /> build fast and
        <br /> lean
      </>
    ),
    withIndent: true,
  },
  colored: {
    title: (
      <>
        Interested in Estimation for
        <br /> design/
        <br />
        development?
      </>
    ),
    strongText: "request a consultation today",
    uppercase: "Project estimate",
    link: "#contact-form",
    noSchemaImg: true,
    withIndent: false,
  },
}

export const SERVICES_LIST = [
  {
    id: 1,
    title: "Digital Product Design",
    text:
      "We create a design that users want to interact with. Our experienced designers create beautiful digital designs for web and mobile applications with intelligent user experience.",
    link: "/product-design/",
    icon: "icon-pencil",
  },
  {
    id: 2,
    title: "MVP Development",
    text:
      "Have a business idea? Start with a Minimal Valuable Product. It allows you to test the concept’s viability and saves you from the hassle of significant investments in creating a full-fledged digital product.",
    link: "/mvp-development/",
    icon: "icon-rocket",
  },
]

export const ESTIMATION_STEPS = [
  { id: 1, title: "Upload your Brief" },
  { id: 2, title: "Discovery Interview" },
  {
    id: 3,
    title: <>System Design &&nbsp;Architecture Session</>,
  },
  {
    id: 4,
    title: <>Project Planning &&nbsp;Estimation Session</>,
  },
]

export const SERVICES = {
  items: [
    {
      id: 1,
      title: <span>Efficient Resource Allocation</span>,
      text: (
        <p>
          Software development cost estimation and planning ensures you don’t
          under- or over-commit resources. Without it, you could end up
          overwhelmed with unfinished tasks or wasting time and effort on idle
          tasks.
        </p>
      ),
      link: "",
    },
    {
      id: 2,
      title: <span>Enhanced Collaboration</span>,
      text: (
        <p>
          The discovery stage and cost estimation help align teams and
          stakeholders on the project’s scope, goals, deadlines, and
          expectations. This shared understanding fosters better collaboration
          and ensures everyone is on the same page throughout the project.
        </p>
      ),
      link: "",
    },
    {
      id: 3,
      title: <span>Effective Risk Management</span>,
      text: (
        <p>
          Software estimations serve as crucial reality checks for startups and
          SMBs. Knowing timeframes and accurate budgets allows you to prioritize
          effectively, allocate resources wisely, and even reduce the stress of
          looming deadlines.
        </p>
      ),
      link: "",
    },
  ],
}

export const STAGES = [
  {
    id: 1,
    title: "Send Your Brief",
    text:
      "Please share your project brief with us so we can understand your goals, vision, and initial requirements. It will help to outline your project’s scope and objectives.",
    label: {
      text: "client part",
      dark: true,
    },
  },
  {
    id: 2,
    title: "Discovery Interview",
    text:
      "Let's dive deeper into your brief during a one-on-one discovery session. We'll discuss your project's specifics, identify key priorities, and address any questions.",
    label: {
      text: "client&LaSoft collaboration",
      dark: false,
    },
  },
  {
    id: 3,
    title: "High-level Project Structure",
    text:
      "During the workshop, we present a structure of your project, the so-called sitemap, as our vision of what your product should look like, depending on the information you provide. The sitemap defines the main components, the technical requirements, and the key deliverables. It's a blueprint that sets the direction for the architecture and design of your software.",
  },
  {
    id: 4,
    title: "Compile a List of Tasks and Resources",
    text: (
      <>
        Our AI tool helps divide the project into manageable stages, making a
        detailed list of tasks and resources needed to complete them. It allows
        a more accurate assessment of the time and cost.
        <br />
        Tasks may include research and planning, design, front-end/back-end
        development process, content writing, testing, and product launch.
      </>
    ),
  },
  {
    id: 5,
    title: "Defining Ideal Team Size",
    text:
      "Optimal project software estimation also includes defining the ideal team size and composition. Project team planning impacts smooth project progress, meeting deadlines, and efficiently using resources.",
  },
  {
    id: 6,
    title: "Project Planning & Estimation Session",
    text:
      "During the workshop, we present detailed final software development estimates for costs, project timelines, and resource needs. We also outline strategies to optimize your project for efficiency and quality.",
  },
]

export const TABS_BUTTONS = [
  { name: "automated", displayedName: "Automated Insurance" },
  { name: "personalised", displayedName: "Fitness app" },
  { name: "intelligent", displayedName: "Intelligent AI" },
]

export const TABS_TEXT_LIST = [
  {
    title: "Automated Insurance Quote Generation",
    text: (
      <>
        <p>
          An automated system that collects client information using smart forms
          gathers quotes from brokers and sends the best option directly to your
          client.
        </p>
        <ul>
          <li>
            The overall timeline: <strong>9-10 months</strong>
          </li>
          <li>
            Expected effort: <strong>5,500 - 5,800 hours</strong>
          </li>
          <li>
            Expected cost: <strong>$160k-$180k</strong>
          </li>
        </ul>
      </>
    ),
    image: "automated",
    chart: {
      ...COMMON_CHARTS_DATA,
      appId: 1,
      data: [
        {
          title: "Back-end Development",
          value: 756,
          color: "#c58c00",
        },
        {
          title: "Front-end Development",
          value: 588,
          color: "#e6a521",
        },
        {
          title: "Project Management",
          value: 360,
          color: "#f0b528",
        },
        {
          title: "Quality Assurance",
          value: 350,
          color: "#f4c145",
        },
        {
          title: "UI/UX Design",
          value: 300,
          color: "#f6d173",
        },
      ],
    },
  },
  {
    title: "Personalised fitness app",
    text: (
      <p>
        The service offers <strong>one-on-one online coaching</strong> tailored
        to users unique needs. The user journey begins with a
        <strong> comprehensive assessment</strong> to identify fitness goals,
        experience level, and other medical considerations. Based on user input,
        personal trainer curate a <strong>personalized fitness plan</strong> to
        help user to achieve his/her aspirations.
      </p>
    ),
    image: "personalised",
    chart: {
      ...COMMON_CHARTS_DATA,
      appId: 2,
      data: [
        {
          title: "Back-end Development",
          value: 1220,
          color: "#c58c00",
        },
        {
          title: "Quality Assurance",
          value: 960,
          color: "#DF9500",
        },
        {
          title: "Mobile Development",
          value: 680,
          color: "#e6a521",
        },
        {
          title: "Project Management",
          value: 640,
          color: "#f4c145",
        },
        {
          title: "Front-end Development",
          value: 588,
          color: "#f6d173",
        },
        {
          title: "UI/UX Design",
          value: 550,
          color: "#F9E0A2",
        },
      ],
    },
  },
  {
    title: <>Intelligent Conversational&nbsp;AI (MVP)</>,
    text: (
      <>
        <p>
          This project seeks to develop an innovative platform that empowers
          universities to create and deploy <strong>tailored chatbots </strong>
          to enhance student engagement and streamline communication. By
          <strong> providing complete access to student interactions</strong>,
          universities can gain valuable insights and continuously refine the
          chatbot's behavior to meet evolving needs.
        </p>
        <p>
          By leveraging this platform, universities can elevate student support,
          improve administrative efficiency, and foster a more connected campus
          community.
        </p>
      </>
    ),
    image: "intelligent",
    chart: {
      ...COMMON_CHARTS_DATA,
      appId: 3,
      data: [
        {
          title: "Back-end Development",
          value: 274,
          color: "#c58c00",
        },
        {
          title: "Front-end Development",
          value: 202,
          color: "#e6a521",
        },
        {
          title: "UI/UX Design",
          value: 162,
          color: "#f0b528",
        },
        {
          title: "Project Management",
          value: 160,
          color: "#f4c145",
        },
        {
          title: "Quality Assurance",
          value: 120,
          color: "#f6d173",
        },
      ],
    },
  },
]

export const OPTIONS = [
  {
    id: 1,
    title: "Quick, Reliable Software Estimate",
    text:
      "Using advanced AI and over a decade of experience, we provide precise estimates you can trust.",
  },
  {
    id: 2,
    title: "AI–Driven Insights",
    text:
      "Our calibration estimation AI model has been fine-tuned for over 10 years to ensure accurate predictions based on real-world project data from 500+ cases.",
  },
  {
    id: 3,
    title: "Comprehensive Planning",
    text:
      "We don't just do software development time estimation; we help you design, architect, and plan your project for success.",
  },
  {
    id: 4,
    title: "Cover Your Business Needs:",
    text:
      "Whether you're a startup founder, investor, stakeholder, or CEO, our service is customized to meet your unique project needs and requirements.",
  },
]

export const ACCORDION_TEXT = [
  {
    id: 1,
    title:
      "Which technique is commonly used for estimation software development in LaSoft?",
    text: (
      <>
        <ul>
          <li>
            <strong>High-level Structure and Agile Estimation:</strong> We
            define the high-level structure of each project, focusing on the
            modules' complexity, and compare this structure with similar
            projects in our database.
          </li>
          <li>
            <strong>Expert Opinion:</strong> We rely on the experience of our
            business analytics, software developers, and project managers.
          </li>
          <li>
            <strong>Analogous Estimation:</strong> Using historical data from
            500+ projects from our portfolio to predict time and cost.
          </li>
          <li>
            <strong>Parametric Estimation:</strong> Using mathematical models
            based on project size and complexity.
          </li>
        </ul>
        <p>
          At LaSoft, we use a combination of these techniques enhanced by
          LaEstimator, the tool developed by our team for cost estimation in
          software engineering.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: "What are the 3 components of estimating projects?",
    text: (
      <>
        <p>
          The three key components of software cost estimation in software
          project management are:
        </p>
        <ul>
          <li>
            <strong>Scope:</strong> Defining what needs to be done, including
            deliverables, features, and functionality.
          </li>
          <li>
            <strong>Time:</strong> Estimating the duration for each task and the
            overall project timeline.
          </li>
          <li>
            <strong>Cost:</strong> Calculating the financial resources required
            based on time, team size, and technology needs.
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 3,
    title: "Why is estimation important in software development?",
    text: (
      <>
        <p>
          Estimation accuracy builds trust and transparency among stakeholders,
          investors, and software development team members. It allows the
          delivery of commercially successful products on time and within
          budget.
        </p>
        <ul>
          <li>
            <strong>Team Members Allocation:</strong> Ensures the proper team
            size to be assigned to tasks.
          </li>
          <li>
            <strong>Budget planning:</strong> Helps manage financial
            expectations and avoid overspending.
          </li>
          <li>
            <strong>Time Management:</strong> Provides a realistic schedule for
            project completion.
          </li>
        </ul>
      </>
    ),
  },
]
