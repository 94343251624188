import { calculatePercentages, makeLargeAndSmallSegments } from "./helpers"

import { makeLabelWidth } from "./canvas"
import { donutChart } from "./constants"

export const calculateSegments = data => {
  let cumulativeValue = 0
  const totalValue = data.data.reduce((acc, item) => acc + item.value, 0)

  const percentages = calculatePercentages(data.data, data.precision)

  return data.data.map(({ title, value, color }, index) => {
    const start = (cumulativeValue / totalValue) * 2 * Math.PI
    cumulativeValue += value
    const end = (cumulativeValue / totalValue) * 2 * Math.PI

    return {
      start,
      end,
      title,
      value,
      color,
      percentage: percentages[index].percentage,
    }
  })
}

export const calculatePaddingForRadius = (
  canvas,
  data,
  segments,
  radius,
  disableLabels
) => {
  const padding = { top: 0, bottom: 0, left: 0, right: 0 }

  if (disableLabels) return padding

  const lineLength = donutChart.leaderLineWidth(data.appId)
  const width = radius * 2

  const ctx = canvas.getContext("2d")
  if (!ctx) return padding

  const [largeSegments, smallSegments] = makeLargeAndSmallSegments(
    segments,
    donutChart.minPrecisionForMergeIntoOther
  )

  const segmentsWithOther = [...largeSegments]

  segmentsWithOther.push(...smallSegments)

  segmentsWithOther.forEach(segment => {
    const middleAngle = (segment.start + segment.end) / 2 - Math.PI / 2

    const startX = radius + radius * Math.cos(middleAngle)
    const startY = radius + radius * Math.sin(middleAngle)

    const correctedAngleSignX = calculateDirectionLastLabel(segment.percentage)

    const angleSignX =
      (Math.cos(middleAngle) > 0 ? 1 : -1) * correctedAngleSignX
    const angleSignY = Math.sin(middleAngle) > 0 ? 1 : -1

    const endX = startX + lineLength * Math.cos(Math.PI / 4) * angleSignX
    const endY = startY + lineLength * Math.sin(Math.PI / 4) * angleSignY

    const labelWidth = makeLabelWidth(ctx, data, segment)
    const extendedEndX = endX + labelWidth * angleSignX

    if (extendedEndX > width)
      padding.right = Math.max(padding.right, extendedEndX - width)
    if (extendedEndX < 0) padding.left = Math.max(padding.left, -extendedEndX)

    const textBottomY =
      endY +
      donutChart.labelTextPaddingTop(data.appId) +
      donutChart.labelTextPaddingBottom(data.appId) +
      donutChart.labelTextFontSize(data.appId)
    if (textBottomY > width)
      padding.bottom = Math.max(
        padding.bottom,
        textBottomY - width + data.maxRadiusSize
      )

    const textTopY =
      endY -
      donutChart.labelPercentPaddingTop(data.appId) -
      donutChart.labelPercentPaddingBottom(data.appId) -
      donutChart.labelPercentFontSize(data.appId)

    if (textTopY < 0) {
      padding.top = Math.max(padding.top, -textTopY) + data.maxRadiusSize
    }
  })

  return padding
}

export const calculateDirectionLastLabel = percentage => {
  return percentage < donutChart.minPrecisionForDirectionLastLabel ? -1 : 1
}

export const calcCoordinatesData = (
  canvas,
  data,
  segments,
  radius,
  disableLabels
) => {
  const width = radius * 2
  const height = radius * 2

  const padding = calculatePaddingForRadius(
    canvas,
    data,
    segments,
    radius,
    disableLabels
  )

  const newWidth = width + padding.left + padding.right
  const newHeight = height + padding.top + padding.bottom * 2

  const outerRadius = radius * data.outerRadius
  const innerRadius = radius * data.innerRadius

  return {
    radius,
    outerRadius,
    innerRadius,
    centerX: radius + padding.left,
    centerY: radius + padding.top,
    canvasWidth: newWidth,
    canvasHeight: newHeight,
    lineWidth: outerRadius - innerRadius,
    padding,
  }
}

export const calcRadiusFromCanvasWidth = (
  canvas,
  data,
  segments,
  canvasWidth,
  disableLabels
) => {
  let minRadius = donutChart.minCanvasRadius
  let maxRadius = Math.min(Math.ceil(canvasWidth / 2), data.canvasRadius)

  if (maxRadius <= minRadius) return donutChart.minCanvasRadius

  const maxPadding = calculatePaddingForRadius(
    canvas,
    data,
    segments,
    data.canvasRadius,
    disableLabels
  )
  const maxTotalWidth = maxRadius * 2 + maxPadding.left + maxPadding.right

  if (maxTotalWidth < canvasWidth) return data.canvasRadius

  while (Math.round(minRadius) <= Math.round(maxRadius)) {
    const finalRadius = (minRadius + maxRadius) / 2

    const padding = calculatePaddingForRadius(
      canvas,
      data,
      segments,
      finalRadius,
      disableLabels
    )
    const totalWidth = finalRadius * 2 + padding.left + padding.right

    if (Math.round(totalWidth) === canvasWidth) break

    if (totalWidth > canvasWidth) {
      maxRadius = finalRadius
    } else {
      minRadius = finalRadius
    }
  }

  return Math.max(
    Math.floor((minRadius + maxRadius) / 2),
    donutChart.minCanvasRadius
  )
}
