// extracted by mini-css-extract-plugin
export var billetShadow = "stages-module--billet-shadow--22014";
export var colorScheme__background__grayPale = "stages-module--colorScheme__background__gray-pale--a5bfb";
export var icomoon = "stages-module--icomoon--650ef";
export var lowercase = "stages-module--lowercase--56596";
export var stagesList = "stages-module--stagesList--1b72a";
export var stagesList__wrapper = "stages-module--stagesList__wrapper--df5cf";
export var transition = "stages-module--transition--2a4bc";
export var transitionBackground = "stages-module--transition-background--364e8";
export var transitionReverse = "stages-module--transition-reverse--fd0e4";
export var uppercase = "stages-module--uppercase--82d1b";