import React from "react"
import cx from "classnames"
import { Link } from "gatsby"
import loadable from "@loadable/component"

import {
  TITLES,
  SERVICES_LIST,
  ESTIMATION_STEPS,
  SERVICES,
  OPTIONS,
  ACCORDION_TEXT,
} from "../data/software-project-estimation"
import { IMG } from "../const"
import Layout from "../components/layout"
import "../assets/styles/commons/_common-kit.scss"
import * as styles from "../assets/styles/software-project-estimation.module.scss"
import Headline from "../components/headline"
import Stages from "../components/software-project-estimation/stages"
import Tabs from "../components/software-project-estimation/tabs"
import SchemaBlock from "../components/software-project-estimation/schema-block"
import ColoredSection from "../components/colored-section"

const TitleBlock = loadable(() => import("../components/title-block"))
const Accordion = loadable(() => import("../components/accordion"))
const ServiceCard = loadable(() =>
  import("../components/carousels/ServiceCard")
)
const ServicesList = loadable(() => import("../components/services-list"))
const ContactForm = loadable(() => import("../components/contact-form"))

const SoftwareProjectEstimation = () => (
  <Layout
    pageTitle="Software Project Estimation"
    metaDescription="Get an accurate software project estimation 10 times faster using a unique AI-based solution covering data from 500+ software project estimates!"
    defaultImage={`${IMG.THUMBNAILS}/thumbnail-software-project-estimation.jpg`}
  >
    {({ width, handleOpen, handleFirstTimeUser }) => {
      return (
        <main
          className={cx(styles.softwareProjectEstimationBlock, "common-kit")}
        >
          <article>
            <Headline>
              <div className={cx(styles.headline, "headline")}>
                <div className={cx(styles.headline_grid, "headline_grid")}>
                  <p className={"strongText"}>
                    our ai accurately estimates costs and timelines
                  </p>
                  <h1 className={"withIndent"}>
                    <strong>Software</strong> development estimation
                  </h1>
                  <div className={"rightColumn"}>
                    <div>
                      <p>
                        <strong>
                          Accurate cost and timeframe estimates are key to
                          planning your digital product launch.
                        </strong>
                      </p>
                      <p>
                        The complexity of software development and the
                        unpredictability of requirements pose a challenge. You
                        do not want to go over budget and be late with your
                        product.
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className={styles.textColumns}>
                    <p>
                      With <strong>10+ years of experience</strong> and an
                      <strong> AI-powered tool</strong> backed by 500+ projects,
                      we deliver accurate project estimates
                    </p>
                    <ul>
                      <li>
                        An <strong>accurate</strong> project estimation
                      </li>
                      <li>
                        Done within <strong>3 days</strong>
                      </li>
                      <li>
                        <strong>For free</strong>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <Link
                      to="#contact-form"
                      className="goldFatLink softestimate-estimate"
                    >
                      Let’s estimate
                    </Link>
                  </div>
                </div>
              </div>
            </Headline>

            <section className={styles.screenshots}>
              <picture>
                <source
                  media="(max-width: 415px)"
                  srcSet={`${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-poject-mob.avif,	${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-poject-mob@2x.avif 2x,	${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-poject-mob@3x.avif 3x`}
                  type="image/avif"
                />
                <source
                  media="(max-width: 415px)"
                  srcSet={`${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-poject-mob.webp,	${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-poject-mob@2x.webp 2x,	${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-poject-mob@3x.webp 3x`}
                  type="image/webp"
                />
                <source
                  media="(max-width: 415px)"
                  srcSet={`${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-poject-mob.png,	${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-poject-mob@2x.png 2x,	${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-poject-mob@3x.png 3x`}
                />

                <source
                  media="(min-width: 416px)"
                  srcSet={`${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-dev-estimation.avif,	${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-dev-estimation@2x.avif 2x,	${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-dev-estimation@3x.avif 3x`}
                  type="image/avif"
                />
                <source
                  media="(min-width: 416px)"
                  srcSet={`${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-dev-estimation.webp,	${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-dev-estimation@2x.webp 2x,	${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-dev-estimation@3x.webp 3x`}
                  type="image/webp"
                />
                <source
                  media="(min-width: 416px)"
                  srcSet={`${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-dev-estimation.png,	${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-dev-estimation@2x.png 2x,	${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-dev-estimation@3x.png 3x`}
                />
                <img
                  src={`${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-dev-estimation@3x.png`}
                  alt="Screen of estimation"
                  width="1290"
                  height="996"
                  loading="lazy"
                />
              </picture>
            </section>

            <section className={cx("section", styles.textOutline)}>
              <p className={styles.textOutline__dash}>
                <strong>
                  Our team offers you an accurate and reliable budget & timeline
                  estimation fast. History-based estimation will be provided
                  within days not weeks.
                </strong>
              </p>
            </section>

            <section className={cx(styles.optionsListBlock, "section")}>
              <div className={styles.strongText}>
                <h2>How it works</h2>
              </div>
              <ul className={styles.optionsList}>
                {ESTIMATION_STEPS.map(option => (
                  <li key={option.id}>
                    <h3>{option.title}</h3>
                    <div className={styles.optionsList__decorativeLine} />
                  </li>
                ))}
              </ul>
            </section>

            <section className="section">
              <TitleBlock options={TITLES.reasons} />
              <ServicesList services={SERVICES.items} />
            </section>

            <section className="section">
              <TitleBlock options={TITLES.plan} />
              <div className={styles.twoColumns}>
                <div>
                  <picture>
                    <source
                      srcSet={`${IMG.SOFTWARE_PROJECT_ESTIMATION}/Andriy-Tatchyn.avif`}
                      type="image/avif"
                    />
                    <source
                      srcSet={`${IMG.SOFTWARE_PROJECT_ESTIMATION}/Andriy-Tatchyn.webp`}
                      type="image/webp"
                    />
                    <source
                      srcSet={`${IMG.SOFTWARE_PROJECT_ESTIMATION}/Andriy-Tatchyn.jpg`}
                    />
                    <img
                      src={`${IMG.SOFTWARE_PROJECT_ESTIMATION}/Andriy-Tatchyn.jpg`}
                      alt="Andriy Tatchyn, Chief Commercial Officer"
                      width="324"
                      height="518"
                      loading="lazy"
                    />
                  </picture>
                </div>
                <div>
                  <h3>Andriy Tatchyn</h3>
                  <span>Chief Commercial Officer</span>
                  <p>
                    <span />
                    For the past decade, we've tested and refined various
                    estimation methodologies. This experience has culminated in
                    a proven set of methods and tools that enable us to deliver
                    rapid and accurate software project estimations and budgets.
                  </p>
                  <p>
                    So now you can get your Software Project Plan and Budget
                    within a week, not months, even for a complex system.
                  </p>
                  <Link to="#contact-form" className="goldFatLink">
                    Book a free consultation
                  </Link>
                </div>
              </div>
            </section>

            <section className="section">
              <TitleBlock options={TITLES.stages} />
              <Stages />
            </section>

            <section className={cx("section", styles.banner)}>
              <div>
                <div className={styles.banner__img} />

                <div className={styles.banner__text}>
                  <h2>fixed cost project development</h2>
                  <p>
                    As soon as we estimate a project, it is developed at a fixed
                    price.
                  </p>

                  <div className={styles.banner__buttons}>
                    <button
                      type="button"
                      className="goldFatLink softestimate-fiftyoff"
                      onClick={handleFirstTimeUser}
                    >
                      First time user 50% off
                    </button>
                    <button
                      type="button"
                      className={cx("softestimate-estimate-two", styles.link)}
                      onClick={handleOpen}
                    >
                      Project Estimate
                    </button>
                  </div>
                </div>
              </div>
            </section>

            <section className="section">
              <TitleBlock options={TITLES.charts} />
              <SchemaBlock screenWidth={width} />
              <Tabs />
            </section>

            <section className={cx(styles.costOptions, "section")}>
              <TitleBlock options={TITLES.options} />
              <ul className={styles.costOptionsList}>
                {OPTIONS.map(option => (
                  <li key={option.id}>
                    <h3 className={styles.costOptions__title}>
                      {option.title}
                    </h3>
                    <p>{option.text}</p>
                  </li>
                ))}
              </ul>
            </section>

            <ColoredSection options={TITLES.colored} />

            <section className={cx(styles.services, "section")}>
              <TitleBlock options={TITLES.related_services} />
              <div className={styles.servicesWrapper}>
                <div>
                  {SERVICES_LIST.map(item => (
                    <ServiceCard key={item.id} item={item} />
                  ))}
                </div>
              </div>
            </section>

            <section className={cx("section", styles.accordionBlock)}>
              <TitleBlock options={TITLES.accordion} />
              <div className={styles.accordionWrapper}>
                <Accordion options={ACCORDION_TEXT} />
              </div>
            </section>

            <section className={cx("section", styles.textOutline)}>
              <p className={styles.textOutline__dash}>
                Our services include
                <strong> various software development estimation </strong>
                solutions that fit different needs. We use advanced tools
                developed by <strong>our professional developers</strong> for
                accurate estimates and parametric modeling techniques. Our
                <strong> skilled business analysts</strong> bring industry
                experience and best practices to assess the scope and
                functionality of <strong>your future product</strong> and
                prepare the proper parameters for input. Focusing on software
                requirements and <strong>agile software development </strong>
                methodology improves outcomes and delivers results that match
                project scope and quality requirements.
              </p>
            </section>
          </article>

          <section className="section footerForm" id="contact-form">
            <ContactForm formStatID="CommonFooter" />
          </section>
        </main>
      )
    }}
  </Layout>
)

export default SoftwareProjectEstimation
