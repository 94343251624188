import { useEffect, useRef, useState, useCallback, useMemo } from "react"

import { throttle } from "./helpers"

import { calcCoordinatesData, calcRadiusFromCanvasWidth } from "./coordinates"
import { donutChart } from "./constants"

export const useResizeCanvas = (
  canvasRef,
  containerRef,
  coordinatesRef,
  segmentsRef,
  drawRef,
  data
) => {
  const previousWidthRef = useRef(null)

  useEffect(() => {
    let isMounted = true
    const canvas = canvasRef.current
    const container = containerRef.current

    if (!canvas || !container) return

    const throttledDraw = throttle(() => drawRef.current(), 20)

    const resizeObserver = new ResizeObserver(() => {
      if (isMounted) {
        const containerWidth = container.clientWidth

        if (previousWidthRef.current !== containerWidth) {
          previousWidthRef.current = containerWidth
          const disableLabels =
            containerWidth < donutChart.minContainerWidthForRenderLabels

          const radius = calcRadiusFromCanvasWidth(
            canvas,
            data,
            segmentsRef.current,
            containerWidth,
            disableLabels
          )

          coordinatesRef.current = calcCoordinatesData(
            canvas,
            data,
            segmentsRef.current,
            radius,
            disableLabels
          )

          throttledDraw()
        }
      }
    })

    resizeObserver.observe(container)

    return () => {
      resizeObserver.unobserve(container)
      isMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export const useOpen = (openProp = false) => {
  const [open, setOpen] = useState(openProp)

  const toggleOpen = useCallback(
    open => () => setOpen(state => open ?? !state),
    []
  )

  return useMemo(() => ({ open, toggleOpen }), [open, toggleOpen])
}
