// extracted by mini-css-extract-plugin
export var accordionBlock = "software-project-estimation-module--accordionBlock--bb8ed";
export var accordionWrapper = "software-project-estimation-module--accordionWrapper--f36de";
export var banner = "software-project-estimation-module--banner--3412c";
export var banner__buttons = "software-project-estimation-module--banner__buttons--95b45";
export var banner__img = "software-project-estimation-module--banner__img--2ef38";
export var banner__text = "software-project-estimation-module--banner__text--ebbf1";
export var billetShadow = "software-project-estimation-module--billet-shadow--fe89d";
export var colorScheme__background__grayPale = "software-project-estimation-module--colorScheme__background__gray-pale--f0529";
export var costOptions = "software-project-estimation-module--costOptions--c909f";
export var costOptionsList = "software-project-estimation-module--costOptionsList--ad7e6";
export var costOptions__title = "software-project-estimation-module--costOptions__title--21855";
export var headline = "software-project-estimation-module--headline--9bd71";
export var headline_grid = "software-project-estimation-module--headline_grid--108f9";
export var icomoon = "software-project-estimation-module--icomoon--d6d29";
export var link = "software-project-estimation-module--link--1b488";
export var lowercase = "software-project-estimation-module--lowercase--89bed";
export var optionsList = "software-project-estimation-module--optionsList--d7838";
export var optionsListBlock = "software-project-estimation-module--optionsListBlock--3eed6";
export var optionsList__decorativeLine = "software-project-estimation-module--optionsList__decorativeLine--c3b94";
export var screenshots = "software-project-estimation-module--screenshots--69c76";
export var services = "software-project-estimation-module--services--24ff2";
export var servicesWrapper = "software-project-estimation-module--servicesWrapper--ed42b";
export var softwareProjectEstimationBlock = "software-project-estimation-module--softwareProjectEstimationBlock--2b282";
export var strongText = "software-project-estimation-module--strongText--818d1";
export var textColumns = "software-project-estimation-module--textColumns--71dce";
export var textOutline = "software-project-estimation-module--textOutline--7ada5";
export var textOutline__dash = "software-project-estimation-module--textOutline__dash--2649f";
export var transition = "software-project-estimation-module--transition--10d49";
export var transitionBackground = "software-project-estimation-module--transition-background--cebda";
export var transitionReverse = "software-project-estimation-module--transition-reverse--9819b";
export var twoColumns = "software-project-estimation-module--twoColumns--17fab";
export var uppercase = "software-project-estimation-module--uppercase--9ec0c";