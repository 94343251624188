// extracted by mini-css-extract-plugin
export var billetShadow = "tabs-module--billet-shadow--efb8c";
export var colorScheme__background__grayPale = "tabs-module--colorScheme__background__gray-pale--e4368";
export var common__thinScrollbar = "tabs-module--common__thinScrollbar--d8e6e";
export var common__thinScrollbar__bgGold = "tabs-module--common__thinScrollbar__bgGold--178f2";
export var icomoon = "tabs-module--icomoon--790f3";
export var lowercase = "tabs-module--lowercase--360ab";
export var schemaImage = "tabs-module--schemaImage--f8181";
export var tab = "tabs-module--tab--cf075";
export var tabButton = "tabs-module--tabButton--ffeb6";
export var tabsButtons = "tabs-module--tabsButtons--c4205";
export var tabsList = "tabs-module--tabsList--fe4e3";
export var tabsWrapper = "tabs-module--tabsWrapper--93e5a";
export var transition = "tabs-module--transition--e53f9";
export var transitionBackground = "tabs-module--transition-background--94d48";
export var transitionReverse = "tabs-module--transition-reverse--7cb5b";
export var uppercase = "tabs-module--uppercase--0402b";