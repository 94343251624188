import React, { useEffect, useRef } from "react"
import cx from "classnames"

import * as styles from "./tabs.module.scss"

const TabButton = ({ option, active, setActive }) => {
  const buttonRef = useRef(null)

  useEffect(() => {
    document.addEventListener("keydown", handleKeyClicking)
    return () => {
      document.removeEventListener("keydown", handleKeyClicking)
    }
  })

  const handleKeyClicking = e => {
    if (buttonRef.current.contains(e.target) && e.keyCode === 13) {
      const selected = buttonRef.current.dataset.select

      if (selected === active) return
      setActive(selected)
    }
  }

  function changeTab(e) {
    const selected = e.target.dataset.select
    setActive(selected)
  }

  return (
    <button
      type="button"
      className={cx(styles.tabButton, active === option.name ? "active" : "")}
      onClick={changeTab}
      ref={buttonRef}
      data-select={option.name}
    >
      {option.displayedName}
    </button>
  )
}

export default TabButton
