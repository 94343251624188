import React, { useEffect, useState } from "react"
import cx from "classnames"

import { IMG } from "../../../const"
import * as styles from "../tabs/tabs.module.scss"
import TabButton from "./TabButton"

const TABS_BUTTONS = [
  { name: "part-1", displayedName: "Phase 1" },
  { name: "part-2", displayedName: "Phase 2" },
  { name: "part-3", displayedName: "Phase 3" },
]
const TABS_PICTURES = [
  { id: 1, image: "part-1" },
  { id: 2, image: "part-2" },
  { id: 3, image: "part-3" },
]

const SchemaTabs = () => {
  const [active, setActive] = useState(TABS_BUTTONS[0].name)

  useEffect(() => {
    TABS_BUTTONS.forEach(option => {
      if (active === option.name) {
      }
    })
  }, [active])

  return (
    <div className={styles.tabsWrapper}>
      <div className={styles.tabsButtons}>
        {TABS_BUTTONS.map(button => (
          <TabButton
            option={button}
            active={active}
            setActive={setActive}
            key={button.name}
          />
        ))}
      </div>
      <ul className={styles.tabsList}>
        {TABS_PICTURES.map(item => (
          <li
            key={item.image}
            className={cx(styles.tab, active === item.image ? "activeTab" : "")}
            id={item.id}
          >
            <img
              className={styles.schemaImage}
              src={`${IMG.SOFTWARE_PROJECT_ESTIMATION}/tabs/${item.image}.svg`}
              alt={`Schema of possible deviation. Phase ${item.id}`}
              width="344"
              loading="lazy"
            />
          </li>
        ))}
      </ul>
    </div>
  )
}

export default SchemaTabs
