import { makeTruncatedText, reduceValues } from "./helpers"
import { calculateDirectionLastLabel } from "./coordinates"
import { donutChart } from "./constants"

export const drawCanvas = (
  canvas,
  data,
  coordinatesRef,
  segments,
  segmentOpacities,
  disableLabels
) => {
  const ctx = canvas.getContext("2d")
  if (!ctx) return

  ctx.clearRect(0, 0, canvas.width, canvas.height)

  const { current: coordinates } = coordinatesRef

  const correctRadius = { donut: 0, pie: 1 }

  segments.forEach((segment, index) => {
    ctx.globalAlpha = segmentOpacities[index]

    ctx.beginPath()
    ctx.lineWidth = coordinates.lineWidth

    ctx.arc(
      coordinates.centerX,
      coordinates.centerY,
      coordinates.outerRadius -
        coordinates.lineWidth / 2 +
        correctRadius[data.type],
      segment.start - Math.PI / 2,
      segment.end - Math.PI / 2
    )
    ctx.strokeStyle = segment.color
    ctx.stroke()

    if (disableLabels) return

    drawSegmentLine(
      canvas,
      data,
      coordinatesRef,
      segment,
      segments.length,
      index
    )
  })

  ctx.globalAlpha = donutChart.maxOpacity

  if (data.type === "pie") {
    ctx.globalCompositeOperation = "destination-out"

    ctx.strokeStyle = "white"
    ctx.lineWidth = donutChart.segmentStrokeWidth
    ctx.beginPath()
    ctx.arc(
      coordinates.centerX,
      coordinates.centerY,
      ctx.lineWidth / 2,
      0,
      Math.PI * 2
    )
    ctx.fill()

    segments.forEach(segment => {
      const endX =
        coordinates.centerX +
        coordinates.radius * Math.cos(segment.end - Math.PI / 2)
      const endY =
        coordinates.centerY +
        coordinates.radius * Math.sin(segment.end - Math.PI / 2)
      ctx.beginPath()
      ctx.moveTo(coordinates.centerX, coordinates.centerY)
      ctx.lineTo(endX, endY)
      ctx.stroke()
    })

    ctx.lineCap = "butt"
    ctx.globalCompositeOperation = "source-over"
  }

  drawOuterCircle(canvas, data, coordinatesRef)

  if (data.type === "donut") {
    drawCenterText(canvas, data, coordinatesRef)
  }
}

export const drawSegmentLine = (canvas, data, coordinatesRef, segment) => {
  const ctx = canvas.getContext("2d")
  if (!ctx) return

  const { current: coordinates } = coordinatesRef

  const lineLength = donutChart.leaderLineWidth(data.appId)
  const lineColor = data.maxRadiusColor // labels lines
  const lineWidth = data.maxRadiusSize

  const correctedAngleSignX = calculateDirectionLastLabel(segment.percentage)

  const middleAngle = (segment.start + segment.end) / 2 - Math.PI / 2

  const startX =
    coordinates.centerX + coordinates.radius * Math.cos(middleAngle)
  const startY =
    coordinates.centerY + coordinates.radius * Math.sin(middleAngle)

  const angleSignX = (Math.cos(middleAngle) > 0 ? 1 : -1) * correctedAngleSignX
  const angleSignY = Math.sin(middleAngle) > 0 ? 1 : -1

  const endX = startX + lineLength * Math.cos(Math.PI / 4) * angleSignX
  const endY = startY + lineLength * Math.sin(Math.PI / 4) * angleSignY

  ctx.beginPath()
  ctx.moveTo(startX, startY)
  ctx.lineTo(endX, endY)
  ctx.strokeStyle = lineColor
  ctx.lineWidth = lineWidth
  ctx.stroke()

  const labelWidth = makeLabelWidth(ctx, data, segment)
  const extendedEndX = endX + labelWidth * angleSignX

  ctx.beginPath()
  ctx.moveTo(endX, endY)
  ctx.lineTo(extendedEndX, endY)
  ctx.stroke()

  ctx.fillStyle = "#454545"
  ctx.textAlign = "center"
  ctx.textBaseline = "top"

  const textLabelY = endY + donutChart.labelTextPaddingTop(data.appId)

  const { canvasWidth } = coordinatesRef.current

  if (canvasWidth < 430) {
    ctx.font = "400 12px sans-serif"

    const truncatedText = makeTruncatedText(ctx, segment.title, labelWidth)
    ctx.fillText(truncatedText, (endX + extendedEndX) / 2, textLabelY)
  } else {
    ctx.font = "400 13px sans-serif"

    const words = segment.title.split(" ") // Split title by whitespace
    const line1 = words[0] // First word
    const line2 = words.slice(1).join(" ") // Second word
    ctx.fillText(line1, (endX + extendedEndX) / 2, textLabelY - 3)
    ctx.fillText(line2, (endX + extendedEndX) / 2, textLabelY + 12)
  }

  ctx.font = "600 16px sans-serif"
  const textPercentY =
    endY -
    donutChart.labelPercentPaddingBottom(data.appId) -
    donutChart.labelPercentFontSize(data.appId)

  ctx.fillText(
    `${segment.value} hours`,
    (endX + extendedEndX) / 2,
    textPercentY
  )
}

export const drawCenterText = (canvas, data, coordinatesRef) => {
  const ctx = canvas.getContext("2d")
  if (!ctx) return

  const { current: coordinates } = coordinatesRef

  const totalValue = reduceValues(data)
  const topText = totalValue.toString()
  const bottomText = data.totalText

  ctx.textBaseline = "middle"
  ctx.textAlign = "center"

  ctx.font = donutChart.totalValueFont(data.appId)
  ctx.fillStyle = "#454545"

  ctx.fillText(
    topText,
    coordinates.centerX,
    coordinates.centerY - donutChart.gapYTotalText(data.appId) / 2
  )

  ctx.font = donutChart.totalTextFont(data.appId)
  ctx.fillStyle = "#454545"

  const textMaxWidth =
    (coordinates.innerRadius - donutChart.paddingXTotalText(data.appId) / 2) * 2
  const truncatedText = makeTruncatedText(ctx, bottomText, textMaxWidth)

  ctx.fillText(
    truncatedText,
    coordinates.centerX,
    coordinates.centerY + donutChart.gapYTotalText(data.appId) / 2
  )
}

export const drawOuterCircle = (canvas, data, coordinatesRef) => {
  const ctx = canvas.getContext("2d")
  if (!ctx) return

  const { current: coordinates } = coordinatesRef

  ctx.beginPath()
  ctx.arc(
    coordinates.centerX,
    coordinates.centerY,
    coordinates.radius - data.maxRadiusSize,
    0,
    2 * Math.PI
  )
  ctx.strokeStyle = data.maxRadiusColor || "#f0b528"
  ctx.lineWidth = data.maxRadiusSize
  ctx.stroke()
}

export const makeLabelTextWidth = (ctx, data, { title = "" }) => {
  ctx.font = "400 14px sans-serif"
  const width = Math.min(
    ctx.measureText(title).width,
    donutChart.labelTextMaxWidth(data.appId)
  )
  return Math.max(width, donutChart.labelTextMinWidth(data.appId))
}

export const makeLabelPercentWidth = (ctx, data, { value = "" }) => {
  ctx.font = "600 16px sans-serif"
  return ctx?.measureText(`${value} ${data.unit}`).width || 0
}

export const makeLabelWidth = (ctx, data, segment) =>
  Math.max(
    makeLabelTextWidth(ctx, data, segment),
    makeLabelPercentWidth(ctx, data, segment)
  )
