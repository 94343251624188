import React, { useEffect, useState } from "react"
import cx from "classnames"

import {
  TABS_TEXT_LIST,
  TABS_BUTTONS,
} from "../../../data/software-project-estimation"
import * as styles from "./tabs.module.scss"
import TabButton from "./TabButton"
import ChartCanvas from "../../donut-chart/ChartCanvas"

const Tabs = () => {
  const [active, setActive] = useState(TABS_BUTTONS[0].name)

  useEffect(() => {
    TABS_BUTTONS.forEach(option => {
      if (active === option.name) {
      }
    })
  }, [active])

  return (
    <div className={styles.tabsWrapper}>
      <div className={styles.tabsButtons}>
        {TABS_BUTTONS.map(button => (
          <TabButton
            option={button}
            active={active}
            setActive={setActive}
            key={button.name}
          />
        ))}
      </div>
      <ul className={styles.tabsList}>
        {TABS_TEXT_LIST.map(item => (
          <li
            key={item.image}
            className={cx(styles.tab, active === item.image ? "activeTab" : "")}
            id={item.image}
          >
            <div>
              <h3>{item.title}</h3>
              {item.text}
            </div>
            <div>
              <ChartCanvas data={item.chart} />
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Tabs
