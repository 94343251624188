import { easeInOut } from "./helpers"

import { donutChart } from "./constants"

export const animateOpacities = (
  drawRef,
  segments,
  segmentOpacities,
  targetOpacityMap
) => {
  const startTime = performance.now()
  const animate = () => {
    const elapsed = performance.now() - startTime
    const progress = Math.min(elapsed / donutChart.transitionDuration, 1)
    const easedProgress = easeInOut(progress)

    segments.forEach((_, index) => {
      const targetOpacity = targetOpacityMap[index] || donutChart.maxOpacity
      const currentOpacity =
        segmentOpacities.current[index] || donutChart.maxOpacity
      segmentOpacities.current[index] =
        currentOpacity + (targetOpacity - currentOpacity) * easedProgress
    })

    drawRef.current()

    if (progress < 1) {
      requestAnimationFrame(animate)
    }
  }
  animate()
}

export const updateOpacities = (
  drawRef,
  segments,
  segmentOpacities,
  segmentHovered
) => {
  return ({ reset }) => {
    const targetOpacityMap = segments.map((_, i) =>
      segmentHovered.current[i] || reset
        ? donutChart.maxOpacity
        : donutChart.minOpacity
    )

    animateOpacities(drawRef, segments, segmentOpacities, targetOpacityMap)
  }
}
