export const getCurrentComputedStyle = (cssValue, id) => {
  if (typeof document !== "undefined") {
    const container = document.getElementById(`canvasContainer-${id}`)

    if (!container) {
      return
    }

    return getComputedStyle(container).getPropertyValue(cssValue).trim()
  }
}

export const fontSize = id =>
  parseFloat(getCurrentComputedStyle("font-size", id))
export const fontFamily = id => getCurrentComputedStyle("font-family", id)

export const scale = 2

export const labelText = {
  labelTextMaxWidth: id => fontSize(id) * 5,
  labelTextMinWidth: id => fontSize(id) * 2.5,
  labelTextFontSize: id => fontSize(id) * 0.75,
  labelTextPaddingTop: id => fontSize(id) * 0.5,
  labelTextPaddingBottom: id => fontSize(id) * 0.1875,
}

export const labelPercent = {
  labelPercentFontSize: id => fontSize(id),
  labelPercentPaddingTop: id => fontSize(id) * 0.125,
  labelPercentPaddingBottom: id => fontSize(id) * 0.3125,
}

export const donutChart = {
  maxSegmentLength: 7,
  minContainerWidthForRenderLabels: 320,
  minCanvasRadius: 20,
  minPrecisionForMergeIntoOther: 9,
  minPrecisionForDirectionLastLabel: 7,
  paddingXTotalText: id => fontSize(id) * 0.5,
  gapYTotalText: id => fontSize(id) * 1.5,
  maxOpacity: 1,
  minOpacity: 0.5,
  leaderLineWidth: id => fontSize(id) * 2.25,
  ...labelText,
  labelTextFont: id =>
    `400 ${labelText.labelTextFontSize(id)}px ${fontFamily(id)}`,
  ...labelPercent,
  labelPercentFont: id =>
    `600 ${labelPercent.labelPercentFontSize(id)}px ${fontFamily(id)}`,
  segmentStrokeWidth: 2,
  transitionDuration: 300,
  totalTextFont: id => `400 ${fontSize(id) * 0.875}px ${fontFamily(id)}`,
  totalValueFont: id => `700 ${fontSize(id) * 1.75}px ${fontFamily(id)}`,
}
