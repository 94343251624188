export const handleMouseMoveFn = (
  canvas,
  coordinatesRef,
  segments,
  segmentHoveredRef,
  setTooltipData,
  updateOpacitiesFn,
  mouseLeave
) => event => {
  const rect = canvas.getBoundingClientRect()
  const x = event.clientX - rect.left
  const y = event.clientY - rect.top

  const { current: coordinates } = coordinatesRef

  const angle = Math.atan2(y - coordinates.centerY, x - coordinates.centerX)
  const normalizedAngle = (angle + Math.PI / 2 + 2 * Math.PI) % (2 * Math.PI)
  const distanceFromCenter = Math.sqrt(
    Math.pow(x - coordinates.centerX, 2) + Math.pow(y - coordinates.centerY, 2)
  )

  if (
    distanceFromCenter <= coordinates.outerRadius &&
    distanceFromCenter >= coordinates.innerRadius
  ) {
    const hoveredArray = segments.map(
      segment =>
        normalizedAngle >= segment.start && normalizedAngle < segment.end
    )

    if (hoveredArray.toString() !== segmentHoveredRef.current.toString()) {
      segmentHoveredRef.current = hoveredArray
      segments.forEach(({ title, value, color, percentage }, i) => {
        const hovered = hoveredArray[i]
        if (hovered) {
          setTooltipData({ visible: true, title, value, color, percentage })
          updateOpacitiesFn({ reset: false })
        }
      })
    }
  } else {
    mouseLeave()
  }
}

export const reduceValues = data => {
  let sum = 0
  let compensation = 0

  for (const item of data.data) {
    const correctedValue = item.value - compensation
    const newSum = sum + correctedValue
    compensation = newSum - sum - correctedValue
    sum = newSum
  }

  return sum
}

export const makeFactor = precision => Math.pow(10, precision)

// export const joinSegments = (segments, precision) => {
//   const factor = makeFactor(precision)

//   return segments.reduce(
//     (acc, segment) => {
//       return {
//         percentage: Math.round(
//           ((acc.percentage + segment.percentage) * factor) / factor
//         ),
//         start: Math.min(acc.start, segment.start),
//         end: Math.max(acc.end, segment.end),
//       }
//     },
//     {
//       percentage: 0,
//       start: segments[0].start,
//       end: segments[0].end,
//     }
//   )
// }

export const makeLargeAndSmallSegments = (segments, minPrecision) => {
  const smallSegments = segments.filter(
    segment => segment.percentage <= minPrecision
  )

  const largeSegments = segments.filter(
    segment => segment.percentage > minPrecision
  )

  return [largeSegments, smallSegments]
}

export const calculatePercentages = (data, precision) => {
  const totalValue = data.reduce((acc, item) => acc + item.value, 0)
  const factor = makeFactor(precision)

  const percentages = data.map(item => {
    const percentage =
      Math.round((item.value / totalValue) * 100 * factor) / factor

    return {
      value: item.value,
      percentage,
    }
  })

  const maxIndex = percentages.reduce(
    (maxIdx, item, idx, arr) => (item.value > arr[maxIdx].value ? idx : maxIdx),
    0
  )

  const sumOfOthers = percentages.reduce(
    (sum, item, idx) => (idx !== maxIndex ? sum + item.percentage : sum),
    0
  )

  percentages[maxIndex].percentage =
    Math.round((100 - sumOfOthers) * factor) / factor

  return percentages
}

export const handleDocumentTouch = (canvas, callback) => {
  const handleTouch = event => {
    if (canvas && !canvas.contains(event.target)) {
      callback()
    }
  }

  document.addEventListener("touchstart", handleTouch)
  document.addEventListener("mousedown", handleTouch)

  return () => {
    document.removeEventListener("touchstart", handleTouch)
    document.removeEventListener("mousedown", handleTouch)
  }
}

export const easeInOut = t => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t)

export const throttle = (func, limit) => {
  let lastFunc
  let lastRan
  return () => {
    const now = Date.now()
    if (!lastRan) {
      func()
      lastRan = now
    } else {
      clearTimeout(lastFunc)
      lastFunc = setTimeout(() => {
        if (now - lastRan >= limit) {
          func()
          lastRan = now
        }
      }, limit - (now - lastRan))
    }
  }
}

export const checkIsIOS = () => {
  if (typeof window !== "undefined") {
    const isIPhone = /iPad|iPhone|iPod/.test(navigator.userAgent)
    const isMacintosh = /Macintosh/.test(navigator.userAgent)
    const hasTouchSupport =
      "ontouchstart" in window || navigator.maxTouchPoints > 0

    return (isIPhone || isMacintosh) && hasTouchSupport
  }
  return false
}

export const getScrollbarSizes = () => {
  const verticalScrollbarWidth =
    window.innerWidth - document.documentElement.clientWidth
  const horizontalScrollbarHeight =
    window.innerHeight - document.documentElement.clientHeight

  return {
    verticalScrollbarWidth,
    horizontalScrollbarHeight,
  }
}

export const makeTruncatedText = (ctx, text, maxWidth) => {
  const textWidth = ctx.measureText(text).width
  const points = textWidth <= maxWidth ? "" : "..."
  let truncatedText = text

  while (
    ctx.measureText(truncatedText + points).width > maxWidth &&
    truncatedText.length > 0
  ) {
    truncatedText = truncatedText.slice(0, -1)
  }

  return truncatedText + points
}
