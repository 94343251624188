import React, { useState, useEffect, useRef } from "react"

import { useOpen } from "./hooks"
import { fontSize } from "./constants"
import { checkIsIOS, getScrollbarSizes } from "./helpers"

const isIOS = checkIsIOS()

export const DonutChartTooltipBody = ({ title, value, color }) => {
  return (
    <div className="DonutChartTooltip">
      <div>
        <span
          className="DonutChartTooltip__color"
          style={{ background: color }}
          aria-label={color}
        />
        <span className="DonutChartTooltip__label">{title}</span>
      </div>
      <span className="DonutChartTooltip__value">{value} hours</span>
    </div>
  )
}

const Tooltip = ({
  appId,
  content,
  position = "top",
  paddingOffset = fontSize(appId),
  offset = fontSize(appId) * 0.5,
  hideOnScroll = true,
  hideOnResize = true,
  followCursor = false,
  children,
}) => {
  const { open, toggleOpen } = useOpen()
  const [styles, setStyles] = useState({})
  const tooltipRef = useRef(null)
  const childRef = useRef(null)

  useEffect(() => {
    const onScroll = hideOnScroll ? toggleOpen(false) : undefined
    const onResize = hideOnResize ? toggleOpen(false) : undefined

    if (onScroll) window.addEventListener("scroll", onScroll)
    if (onResize) window.addEventListener("resize", onResize)

    return () => {
      if (onScroll) window.removeEventListener("scroll", onScroll)
      if (onResize) window.removeEventListener("resize", onResize)
    }
  }, [hideOnScroll, hideOnResize, toggleOpen])

  useEffect(() => {
    const updateCursorPosition = event => {
      if (followCursor && tooltipRef.current) {
        const tooltipRect = tooltipRef.current.getBoundingClientRect()
        const {
          verticalScrollbarWidth,
          horizontalScrollbarHeight,
        } = getScrollbarSizes()

        const windowWidth = window.innerWidth
        const windowHeight = window.innerHeight

        let newStyles = { top: 0, left: 0 }

        switch (position) {
          case "top":
            newStyles = {
              top: event.clientY - tooltipRect.height - offset,
              left: event.clientX - tooltipRect.width / 2,
            }
            break
          case "bottom":
            newStyles = {
              top: event.clientY + offset,
              left: event.clientX - tooltipRect.width / 2,
            }
            break
          case "left":
            newStyles = {
              top: event.clientY - tooltipRect.height / 2,
              left: event.clientX - tooltipRect.width - offset,
            }
            break
          case "right":
            newStyles = {
              top: event.clientY - tooltipRect.height / 2,
              left: event.clientX + offset,
            }
            break
          default:
            newStyles = {
              top: event.clientY + offset,
              left: event.clientX - tooltipRect.width / 2,
            }
            break
        }

        const newLeft = newStyles.left ?? 0
        if (
          newLeft + tooltipRect.width >
          windowWidth - paddingOffset - verticalScrollbarWidth
        ) {
          if (position === "right") {
            newStyles.left = event.clientX - tooltipRect.width - offset
          } else if (position === "left") {
            newStyles.left = event.clientX + offset
          } else {
            newStyles.left =
              windowWidth -
              tooltipRect.width -
              paddingOffset -
              verticalScrollbarWidth
          }
        } else if (newLeft < paddingOffset) {
          if (position === "left") {
            newStyles.left = event.clientX + offset
          } else if (position === "right") {
            newStyles.left = event.clientX - tooltipRect.width - offset
          } else {
            newStyles.left = paddingOffset
          }
        }

        const newTop = newStyles.top ?? 0
        if (
          newTop + tooltipRect.height >
          windowHeight - paddingOffset - horizontalScrollbarHeight
        ) {
          if (position === "bottom") {
            newStyles.top = event.clientY - tooltipRect.height - offset
          } else if (position === "top") {
            newStyles.top = event.clientY + offset
          } else {
            newStyles.top =
              windowHeight -
              tooltipRect.height -
              paddingOffset -
              horizontalScrollbarHeight
          }
        } else if (newTop < paddingOffset) {
          if (position === "top") {
            newStyles.top = event.clientY + offset
          } else if (position === "bottom") {
            newStyles.top = event.clientY - tooltipRect.height - offset
          } else {
            newStyles.top = paddingOffset
          }
        }

        setStyles(newStyles)
      }
    }

    const calculateInitialPosition = () => {
      const trigger = childRef.current

      if (tooltipRef.current && trigger) {
        const tooltipRect = tooltipRef.current.getBoundingClientRect()
        const rect = trigger.getBoundingClientRect()

        const {
          verticalScrollbarWidth,
          horizontalScrollbarHeight,
        } = getScrollbarSizes()
        const windowWidth = window.innerWidth - verticalScrollbarWidth
        const windowHeight = window.innerHeight - horizontalScrollbarHeight

        const offsetTop =
          Number(isIOS) && (window.visualViewport?.offsetTop ?? 0)
        const offsetLeft =
          Number(isIOS) && (window.visualViewport?.offsetLeft ?? 0)

        const positions = {
          top: {
            top: rect.top + offsetTop - tooltipRect.height - offset,
            left:
              rect.left + offsetLeft + rect.width / 2 - tooltipRect.width / 2,
          },
          bottom: {
            top: rect.bottom + offsetTop + offset,
            left:
              rect.left + offsetLeft + rect.width / 2 - tooltipRect.width / 2,
          },
          left: {
            top:
              rect.top + offsetTop + rect.height / 2 - tooltipRect.height / 2,
            left: rect.left + offsetLeft - tooltipRect.width - offset,
          },
          right: {
            top:
              rect.top + offsetTop + rect.height / 2 - tooltipRect.height / 2,
            left: rect.right + offsetLeft + offset,
          },
        }

        const newStyles = positions[position]

        if (position === "top" || position === "bottom") {
          if (newStyles.left < paddingOffset) {
            newStyles.left = paddingOffset
          } else if (
            newStyles.left + tooltipRect.width >
            windowWidth - paddingOffset
          ) {
            newStyles.left = windowWidth - tooltipRect.width - paddingOffset
          }

          if (position === "top" && newStyles.top < paddingOffset) {
            newStyles.top = rect.bottom + offset
          } else if (
            position === "bottom" &&
            newStyles.top + tooltipRect.height > windowHeight - paddingOffset
          ) {
            newStyles.top = rect.top - tooltipRect.height - offset
          }
        } else if (position === "left" || position === "right") {
          if (newStyles.top < paddingOffset) {
            newStyles.top = paddingOffset
          } else if (
            newStyles.top + tooltipRect.height >
            windowHeight - paddingOffset
          ) {
            newStyles.top = windowHeight - tooltipRect.height - paddingOffset
          }

          if (position === "left" && newStyles.left < paddingOffset) {
            newStyles.left = rect.right + offset
          } else if (
            position === "right" &&
            newStyles.left + tooltipRect.width > windowWidth - paddingOffset
          ) {
            newStyles.left = rect.left - tooltipRect.width - offset
          }
        }

        setStyles(newStyles)
      }
    }

    if (followCursor) {
      window.addEventListener("mousemove", updateCursorPosition)
    } else if (open) {
      calculateInitialPosition()
    }

    return () => {
      if (followCursor) {
        window.removeEventListener("mousemove", updateCursorPosition)
      }
    }
  }, [open, position, offset, paddingOffset, followCursor, content])

  useEffect(() => {
    if (!content) {
      setStyles({})
    }
  }, [open, content])

  return (
    <>
      {React.cloneElement(children, {
        ref: childRef,
        onMouseEnter: toggleOpen(true),
        onMouseLeave: toggleOpen(false),
        onMouseMove: toggleOpen(true),
      })}
      {content && open && (
        <div
          className="DonutChartTooltip__content"
          ref={tooltipRef}
          style={styles}
        >
          {content}
        </div>
      )}
    </>
  )
}

export default Tooltip
